
import Storage from "@/util/Storage"
import {setAdminCoupon, updateAdminCoupon} from "@/Api/Coupon";
import {IsData, ToastData} from "@/views/Coupon/AdminCouponList/Model";
import {Toast} from "vant";

export function addCoupon(upData:any) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    try{
        IsData.forEach((item,index)=>{
            if ( !(upData[item] && upData[item].length) ) Toast(ToastData[item]);
        })
    }catch (e) {
        console.info("判断异常")
    }
    upData.queryType = 0;
    upData.status = 0;
    Toast.loading({ duration:0,message:"添加中~~" })
    return Promise.resolve(setAdminCoupon({ userId,token },upData).then(res=>{
        Toast.clear()
        if ( res.message.code == "200" ) Toast("添加成功");
        return res.data
    }))
}


export function updateCoupon(upData:any = {},oldData:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    let keyArr = Object.keys(upData);
    let num = 0;
    keyArr.forEach((item)=>{
        if ( oldData[item] && upData[item]  && upData[item] != oldData[item] ) num ++;
    })
    if ( num <= 0 ) Toast("请修改内容")
    upData.id = oldData.id
    Toast.loading({ duration:0,message:"修改中~~" })
    return Promise.resolve(updateAdminCoupon({ userId,token },upData).then(res=>{
        Toast.clear()
        Toast("修改成功")
        return res.data
    }))
}
